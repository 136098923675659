<template>
    <v-app style="background: transparent">
        <v-card>
            <v-card-title>
                Contratos
                <v-spacer></v-spacer>
                <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Buscar"
                        single-line
                        hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
                     class="elevation-1"
                    :loading="loading" loading-text="Loading... Please wait"
                    :headers="headers"
                    :items="contracts"
                    :search="search">
                <template v-slot:item.pasos="{ item }">
                    <v-chip color="green" dark>{{ item.pasos }}</v-chip>
                </template>
                <template v-slot:item.actions="{ item }" >
                    <div style="width: 100px;">
                        <v-icon
                                color="blue darken-2"
                                small
                                class="mr-2"
                                @click="editItem(item)"
                        >
                            mdi-pencil
                        </v-icon>
                        <v-icon
                                color="orange darken-2"
                                small
                                @click="confirmDelete(item)"
                        >
                            mdi-delete
                        </v-icon>
                    </div>
                </template>

            </v-data-table>
            <v-dialog v-model="dialog"
                      max-width="290">
                <v-card>
                    <v-card-title class="headline">Eliminar Contrato</v-card-title>
                    <v-card-text>
                        Se eliminará el contrato
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1"
                               text
                               @click="deleteItem()">
                            Aceptar
                        </v-btn>
                        <v-btn color="green darken-1"
                               text
                               @click="dialog = false">
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-app>

    </template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import service from "@/service/contract.service"

    export default {
        name: "ContractList",
        data () {
            return {
                dialog: false,
                loading: false,
                search: '',
                headers: [
                    {
                        text: 'Nombre',
                        align: 'left',
                        sortable: false,
                        value: 'nombre',
                    },
                    {
                        text: 'Descripcion',
                        align: 'left',
                        sortable: false,
                        value: 'descripcion',
                    },
                    {
                        text: 'Precio',
                        sortable: true,
                        value: 'precio',
                    },
                    {
                        text: 'Numero de pasos',
                        sortable: true,
                        value: 'pasos',
                    },
                    { text: 'Actions', value: 'actions', sortable: false },


                ],
                contracts: [],
                currentItem: {}
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                { title: "Documentos"}, { title: "Contratos registrados" }
            ]);
            this.loading = true;
            service.get().then(x => {
                this.contracts = x.data.data;
                this.loading = false;
            })

        },
        methods: {
            editItem(item) {
                this.$router.push('register/' + item.id);
                this.$route.params.pathMatch
            },
            confirmDelete(item)
            {
                this.dialog = true;
                this.currentItem = item;
            },
            deleteItem(){
                this.dialog = false;
                this.loading = true;
                 const index = this.contracts.findIndex(x => {
                     return x.id === this.currentItem.id;
                 });
                service.delete(this.currentItem.id).then(() => {
                    this.contracts.splice(index, 1);
                    this.loading = false;
                    this.currentItem = {}
                })
            }
        }
    }
</script>

<style scoped>
</style>
